import React from "react";
import { Card } from "react-bootstrap";
import Helmet from "react-helmet";

export default function GLP() {
    return (
        <>
            <Helmet>
                <title>Codigas - GLP</title>
            </Helmet>
            <Card bg="light" className="mb-2 justificado">
                <Card.Body>
                    <Card.Title>Que es el GLP</Card.Title>
                    <Card.Text>
                        Si buscamos definir el GLP, podemos encontrar varias
                        versiones en la web, o en las distintas normas que
                        aplican en la materia, dentro de esas conclusiones, se
                        puede resumir que el GLP es la mezcla de gases licuados
                        presentes en el gas natural o disueltos en el petróleo,
                        que se obtienen en la separación del gas en los pozos
                        petrolíferos/gasíferos, durante el proceso de
                        destilación del petróleo en las refinerías y en los
                        procesos petroquímicos.
                    </Card.Text>
                    <Card.Text>
                        En la norma GE N1 112 de la ex Gas del Estado, se
                        considera que el término "gases licuados de petróleo"
                        debe significar e incluir a todo material que esté
                        compuesto predominantemente por cualquiera de los
                        siguientes hidrocarburos, o una mezcla de ellos:
                        propano, - propileno, butanos (butano normal o
                        iso-butano) y butilenos.
                    </Card.Text>
                    <Card.Text>
                        Una de las características principales de estos gases es
                        que se mantienen en estado de vapor a presión y
                        temperatura ambiente, pero se licuan fácilmente a
                        presiones moderadas o disminuyendo su temperatura.
                    </Card.Text>
                    <Card.Text>
                        Dentro de los mismos, los más destacados para la
                        industria son el propano (C3H8) y el butano (C4H10) y
                        sus mezclas comerciales admitidas por norma.
                    </Card.Text>
                    <Card.Text>
                        Básicamente, el GLP es el combustible alternativo más
                        utilizado en el mundo por ser de fácil almacenaje,
                        transporte y distribución, es amigable con el medio
                        ambiente, posee un gran poder calorífico, permite un
                        óptimo control de su consumo y una inmediata puesta en
                        marcha, ya que la instalación de los envases y los
                        tanques de almacenaje es sencilla y segura.
                    </Card.Text>
                    <br />
                    <Card.Title>
                        Sus características principales lo distinguen porque:
                    </Card.Title>
                    <ul>
                        <li>
                            Es ECOLÓGICO, Posee una Combustión limpia y No
                            contamina: Es un combustible que se quema
                            completamente, reduciendo las emisiones al medio
                            ambiente. Es apto para producciones orgánicas.
                        </li>
                        <li>
                            Tiene un excelente poder calórico, lo que eleva su
                            rendimiento por sobre otros combustibles.
                        </li>
                        <li>
                            Es Imperecedero, No genera corrosión, reduciendo así
                            el mantenimiento y prolongando la vida útil de los
                            recipientes que lo contienen y los artefactos que lo
                            utilizan
                        </li>
                        <li>
                            Bajo presiones moderadas se puede almacenar en
                            grandes volúmenes en estado líquido
                        </li>
                    </ul>
                    <br />
                    <Card.Title>Para que se usa</Card.Title>
                    <Card.Text>
                        En todo el mundo millones de personas utilizan GLP en la
                        industria, el hogar, el transporte, el comercio, la
                        agricultura…
                    </Card.Text>
                    <Card.Text>Sus usos principales son:</Card.Text>
                    <ul>
                        <li>
                            Para la obtención de olefinas, utilizadas para la
                            producción de numerosos productos, entre ellos, la
                            mayoría de los plásticos.
                        </li>
                        <li>
                            Como combustible para automóviles y equipos
                            industriales (autoelevadores)
                        </li>
                        <li>Como combustible de refinería.</li>
                        <li>
                            Como fuente de energía doméstica (a través de
                            garrafas o cilindros, tanques estacionarios, redes
                            de distribución)
                        </li>
                        <li>
                            En Procesos industriales, para calentar y secar
                            productos agrícolas.
                        </li>
                        <li>Como combustible para generación eléctrica.</li>
                        <li>
                            Como combustible para motonáutica y barcos militares
                            y de transporte.
                        </li>
                    </ul>
                    <br />
                    <Card.Title>En el hogar</Card.Title>
                    <Card.Text>
                        El GLP se utiliza para cocinar, calentar el agua y sirve
                        como combustible para generar energía eléctrica.
                    </Card.Text>
                    <ul>
                        <li>Cocción de alimentos, en parrillas y cocina.</li>
                        <li>Calentadores de agua y piscinas climatizadas</li>
                        <li>
                            Fuente de energía para equipos de iluminación,
                            calefacción, heladeras, lavarropas, secarropas,
                            chimeneas, cortacéspedes
                        </li>
                    </ul>
                    <br />
                    <Card.Title>En el Transporte</Card.Title>
                    <ul>
                        <li>
                            Es ideal como combustible para vehículos y
                            embarcaciones
                        </li>
                        <li>
                            Su autonomía y potencia es similar a la de los
                            combustibles líquidos (nafta)
                        </li>
                        <li>
                            Existen más de 28 millones de automóviles en el
                            mundo propulsados a GLP
                        </li>
                        <li>
                            Produce una conducción silenciosa y sin vibraciones
                        </li>
                        <li>
                            Reduce significativamente el costo de consumos en
                            los transportes marítimos y fluviales
                        </li>
                    </ul>
                    <br />
                    <Card.Title>En la Industria</Card.Title>
                    <Card.Text>El GLP tiene alcances industriales</Card.Text>
                    <ul>
                        <li>
                            Genera llamas de alta densidad ideales para
                            fundición y soldadura.
                        </li>
                        <li>
                            Permite el funcionamiento de montacargas que
                            levantan hasta 4 toneladas.
                        </li>
                        <li>
                            Asegura generación eléctrica en zonas apartadas, a
                            través de microturbinas.
                        </li>
                        <li>
                            En grandes industrias, restaurantes y hoteles
                            pequeños confían en el GLP como fuente de energía
                            para numerosas aplicaciones.
                        </li>
                    </ul>
                    <br />
                    <Card.Title>En la Recreación</Card.Title>
                    <Card.Text>
                        Por su versatilidad y sus características ecológicas, lo
                        convierten en un combustible ideal para usos en la
                        recreación, tanto en tierra como en agua
                    </Card.Text>
                    <ul>
                        <li>Se utiliza para alimentar globos aerostáticos.</li>
                        <li>
                            Se utiliza para todo tipo de accesorios de camping,
                            como faroles, parrillas, mecheros.
                        </li>
                    </ul>
                    <br />
                    <Card.Title>En la Agricultura</Card.Title>
                    <Card.Text>
                        El GLP ha sido una solución para mejorar la calidad de
                        vida en el campo, tanto para los que lo habitan como
                        para favorecer el desarrollo del agro
                    </Card.Text>
                    <ul>
                        <li>
                            Es una opción térmica segura, eficiente y amigable
                            con el medio ambiente para el control de malezas y
                            plagas.
                        </li>
                        <li>
                            Tiene gran eficiencia en el manejo climático de
                            cultivos de flores, así; como el secado de frutas y
                            tabaco.
                        </li>
                        <li>
                            Millones de galpones en el mundo mantienen la
                            temperatura ideal gracias al GLP.
                        </li>
                        <li>
                            También se utiliza como combustible de vehículos
                            agrícolas y como repelente contra insectos.
                        </li>
                        <li>
                            Se lo utiliza también para la prevención de las
                            famosas "heladas tardías" que afectan las
                            plantaciones de frutas en distintas zonas de nuestro
                            país
                        </li>
                    </ul>
                    <Card.Text>
                        En Argentina todo lo concerniente a la Industria y la
                        Comercialización del GLP está legislado, normado y
                        controlado por la Secretaría de Energía de la Nación, a
                        través de la Dirección de GLP.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card bg="light" className="mb-2 sticky-bottom" id="tixo_card">
                <Card.Body>
                    <h3 id="tixo_encabezado" className="d-inline pt-3">
                        Artículo escrito por Oscar Gambeta CEO de{" "}
                        <a target="__blank" href="http://tixosa.com">
                            TIXO S.A.
                        </a>
                    </h3>
                    <img
                        className="d-flex d-lg-inline mx-auto ml-lg-4 mb-2"
                        id="tixo_logo"
                        alt="Logo tixo sa"
                        src="/images/tixo_logo.png"
                    />
                </Card.Body>
            </Card>
        </>
    );
}
