import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import Navigation from './components/Navigation.js';
import Inicio from './components/Inicio.js';
import Contacto from './components/Contacto.js';
import QuienesSomos from './components/QuienesSomos';
import Normativas from './components/Normativas.js';
import Glp from './components/GLP.js';
import Prensa from './components/Prensa.js';
import NotFound from './components/404.js';
import ScrollToTop from './components/ScrollToTop.js';
import { Container } from 'react-bootstrap';
import './App.css';

function App() {
    return (
        <main className="App">
            <Helmet>
                <meta charset="utf-8" />
                <title>Codigas</title>
                <meta
                    name="description"
                    content="Camara de operadores y distribuidores de gas licuado CODIGAS"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
            </Helmet>
            <div id="encabezado" className="text-center">
                <img
                    id="logo"
                    className="mt-3"
                    src="/images/codigas_logo.jpg"
                    alt="Codigas - Camara de operadores y distribuidores de gas licuado"
                />
            </div>
            <Router>
                <ScrollToTop />
                <Navigation />
                <div id="navegacion"></div>
                <div id="contenido">
                    <Container className="pt-5">
                        <Switch>
                            <Route path="/" exact component={Inicio} />
                            <Route
                                path="/quienes-somos"
                                exact
                                component={QuienesSomos}
                            />
                            <Route
                                path="/normativas"
                                exact
                                component={Normativas}
                            />
                            <Route path="/glp" exact component={Glp} />
                            <Route
                                path="/contacto"
                                exact
                                component={Contacto}
                            />
                            <Route path="/prensa" exact component={Prensa} />
                            <Route exact component={NotFound} />
                        </Switch>
                    </Container>
                </div>
            </Router>
        </main>
    );
}

export default App;
