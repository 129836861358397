import React from "react";
import Carousel from "react-bootstrap/Carousel";
const carrusel2 = [...Array(9)].map((e,i) => {
    return (
        <Carousel.Item>
            <img
                src={
                    "/images/distribucionEnQuiebra/imagen" +
                    i.toString() +
                    ".jpg"
                }
                width="80%"
                className="imagenCarrousel"
                alt={"imagen"+i}
            />
        </Carousel.Item>
    );
});
export default function Slider() {
    return (
        <>
            <Carousel interval={10000000}>{carrusel2}</Carousel>
        </>
    );
}
