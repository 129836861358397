import React from "react";
import { Card, Button } from "react-bootstrap";
import Helmet from "react-helmet";

function CardAndImage({
    imagePath,
    imageAlt,
    title,
    cardText,
    buttonPath,
    buttonText,
}) {
    return (
        <Card bg="light" className="mb-2 mt-2">
            <div className="row no-gutters">
                <div className="col-md-4">
                    <Card.Img className="p-3" src={imagePath} alt={imageAlt} />
                </div>
                <Card.Body className="col-md-8 px-3">
                    <Card.Title className="">{title}</Card.Title>
                    <Card.Text>{cardText}</Card.Text>
                    <Button className="mt-4" target="__blank" href={buttonPath}>
                        {buttonText}
                    </Button>
                </Card.Body>
            </div>
        </Card>
    );
}

export default function Inicio() {
    return (
        <>
            <Helmet>
                <title>Codigas</title>
            </Helmet>
            <Card>
                <Card.Body>
                    <Card.Title>
                        <h2>
                            El programa hogar de imposible cumplimiento para la
                            distribucion de G.L.P.
                        </h2>
                    </Card.Title>
                    <Button
                        className="mt-4"
                        target="__blank"
                        href="/articulos/Comunicado de prensa.pdf"
                    >
                        Leer más
                    </Button>
                </Card.Body>
            </Card>
            <CardAndImage
                imagePath="images/codigas_logo2.jpg"
                imageAlt="Codigas"
                title="Proyecto de regularización mercado de G.L.P. Revendedores Independientes."
                buttonPath="/lista_normativas/proyecto-revendedor.pdf"
                buttonText="Ver el proyecto"
            />
            <div className="mb-4 embed-responsive embed-responsive-21by9 negro">
                <video controls name="media">
                    <source
                        src="https://codigas.org.ar/Videos/video-coronavirus.mp4"
                        type="video/mp4"
                    />
                </video>
            </div>
            <CardAndImage
                imagePath="images/glp.jpg"
                imageAlt="Distibucion del glp"
                title="La Distribución del GLP"
                cardText="Desde Codigas nos comprometemos a hacer llegar a los hogares las garrafas y cilíndros de GLP"
                buttonPath="/glp"
                buttonText="Más sobre el GLP"
            />
            <CardAndImage
                imagePath="images/programa_hogar.jpg"
                imageAlt="programa hogar foto"
                title="Mapas de sítios de venta del programa hogar"
                buttonPath="https://www.argentina.gob.ar/energia/programahogar/conoce-las-distribuidoras-y-fraccionadoras-de-recarga-de-garrafas-de-tu-provincia-y-los-precios"
                buttonText="Ir al mapa"
            />
        </>
    );
}
