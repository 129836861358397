import React from "react";
import Helmet from "react-helmet";
export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>Pagina no encontrada</title>
                <meta name="description" content="error página no encontrada" />
                <meta name="robots" content="noindex" />
            </Helmet>
            <h2>Parece que la dirección solicitada no existe</h2>
            <span>Para volver al inicio haga click </span>
            <a href="/">aquí</a>
        </>
    );
}
