import React from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import Item from './listgroup';
import Slider from './Slider.js';

export default function Prensa() {
    return (
        <>
            <Helmet>
                <title>Codigas - Prensa</title>
            </Helmet>
            <Card bg="light" className="mb-2 mt-2">
                <Card.Body>
                    <h2>
                        Ver nota de prensa de Telefe Noticias sobre la
                        movilización
                    </h2>
                    <Button
                        className="mt-4"
                        target="__blank"
                        href="https://telefenoticias.com.ar/actualidad/se-movilizan-las-distribuidoras-de-gas-por-riesgo-de-quiebra/"
                    >
                        <h5>Ir a la nota</h5>
                    </Button>
                </Card.Body>
            </Card>
            <div className="mb-4 embed-responsive embed-responsive-21by9 negro">
                <video controls name="media">
                    <source
                        src="https://codigas.org.ar/Videos/NOTA_RICARDO_AZAR_18-09.mp4"
                        type="video/mp4"
                    />
                </video>
            </div>
            <Card bg="light" className="mb-2 mt-2">
                <Slider />
                <Card.Body>
                    <Card.Title className="">
                        Comunicado de prensa sobre la movilización
                    </Card.Title>
                    <Button
                        className="mt-4"
                        target="__blank"
                        href="/Articulos/ComunicadoDePrensaMovilizacion.pdf"
                    >
                        Ver el comunicado
                    </Button>
                </Card.Body>
            </Card>
            <Card bg="light" id="novedades">
                <Card.Body>
                    <Card.Title>Prensa CODIGAS</Card.Title>
                    <span>Editado por la revista </span>
                    <a target="__blank" href="http://expansionweb.com.ar/">
                        {' '}
                        Expansión Web{' '}
                    </a>
                    <a href="http://expansionweb.com.ar/">
                        <img
                            className="otros_logos d-flex d-lg-inline mx-auto ml-lg-4 mb-2"
                            id="expansion_logo"
                            alt=""
                            src="/images/EXPANSION_WEB.jpg"
                        />
                    </a>
                    <ListGroup>
                        <Item
                            path="/articulos/CODIGAS-Abril-2021.pdf"
                            text="Codigas Abril 2021"
                        />
                        <Item
                            path="/articulos/CODIGAS-Febrero-2021.pdf"
                            text="Codigas Febrero 2021"
                        />
                        <Item
                            path="/articulos/CODIGAS-Octubre-2020.pdf"
                            text="Codigas Octubre 2020"
                        />
                        <Item
                            path="/articulos/CODIGAS-Agosto-2020.pdf"
                            text="Codigas Agosto 2020"
                        />
                        <Item
                            path="/articulos/CODIGAS-Junio-2020.pdf"
                            text="Codigas Junio 2020"
                        />
                        <Item
                            path="/articulos/CODIGAS-Abril-2020.pdf"
                            text="Codigas Abril 2020"
                        />
                        <Item
                            path="/articulos/CODIGAS-Febrero-2020.pdf"
                            text="Codigas Febrero 2020"
                        />
                        <Item
                            path="/articulos/CODIGAS-Diciembre-2019.pdf"
                            text="Codigas Diciembre 2019"
                        />
                        <Item
                            path="/articulos/CODIGAS-Octubre-2019.pdf"
                            text="Codigas Octubre 2019"
                        />
                        <Item
                            path="/articulos/CODIGAS-Agosto-2019.pdf"
                            text="Codigas Agosto 2019"
                        />
                        <Item
                            path="/articulos/CODIGAS-Junio-2019.pdf"
                            text="Codigas Junio 2019"
                        />
                        <Item
                            path="/articulos/CODIGAS-Abril-2019.pdf"
                            text="Codigas Abril 2019"
                        />
                    </ListGroup>
                </Card.Body>
            </Card>
        </>
    );
}
