import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import Helmet from 'react-helmet';
import Item from './listgroup';

export default function Normativas() {
    return (
        <>
            <Helmet>
                <title>Codigas - Normativas</title>
            </Helmet>
            <Card bg="light" className="mb-2">
                <Card.Body>
                    <Card.Title>Marco regulatorio GLP</Card.Title>
                    <ListGroup>
                        <Item
                            path="/lista_normativas/Precios 4-2021 Rs 249.pdf"
                            text="Precios Abril resolución 249"
                        />
                        <Item
                            path="/lista_normativas/Precios 4-21 anexo I Res. 249.pdf"
                            text="Precios Abril resolución 249 anexo I"
                        />
                        <Item
                            path="/lista_normativas/Precios 4-21 anexo II Res. 249.pdf"
                            text="Precios Abril resolución 249 anexo II"
                        />
                        <Item
                            path="/lista_normativas/Precios 4-21 anexo III Res. 249.pdf"
                            text="Precios Abril resolución 249 anexo III"
                        />
                        <Item
                            path="/lista_normativas/Resolución_46-2021.pdf"
                            text="Resolución 46 2021"
                        />
                        <Item
                            path="/lista_normativas/Ley_26020.pdf"
                            text="Ley 26020"
                        />
                        <Item
                            path="/lista_normativas/res_383-2015.docx"
                            text="Resolución 383-2015"
                        />
                        <Item
                            path="/lista_normativas/Resolucion_1097-2015.pdf"
                            text="Resolucion 1097 2015 derogada por la resolución 46"
                        />
                        <Item
                            path="/lista_normativas/res_1097-15_SE-ANEXOS.pdf"
                            text=" Resolución 1097-15-SE-Anexos derogada por la resolución 46"
                        />
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card bg="light" className="mb-2">
                <Card.Body>
                    <Card.Title>Higiene y seguridad en el trabajo</Card.Title>
                    <Item
                        path="/lista_normativas/Protocolo_SRT_para_la_prevención_COVID-19.pdf"
                        text="Protocolo SRT para la prevención del COVID-19"
                    />
                </Card.Body>
            </Card>
        </>
    );
}
