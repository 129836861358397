import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

function NavItem({ path, texto }) {
    console.log("hola");
    return (
        <Nav.Item>
            <Link className="nav-link" to={path}>
                {texto}
            </Link>
        </Nav.Item>
    );
}
export default function navigationBar() {
    return (
        <>
            <Navbar
                className="mt-3 pl-3"
                collapseOnSelect
                expand="md"
                sticky="top"
                bg="info"
                variant="light"
            >
                <Link className="navbar-brand" to="/">
                    CODIGAS
                </Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <NavItem path="/" texto="Página de inicio" />
                        <NavItem path="/quienes-somos" texto="Quiénes somos" />
                        <NavItem path="/normativas" texto="Normativas" />
                        <NavItem path="/glp" texto="GLP" />
                        <NavItem path="/contacto" texto="Contacto" />
                        <NavItem path="/prensa" texto="Prensa" />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}
