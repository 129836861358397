import React from "react";
import { Card } from "react-bootstrap";
import Helmet from "react-helmet";
export default function Contacto() {
    return (
        <>
            <Helmet>
                <title>Codigas - Contacto</title>
            </Helmet>
            <Card bg="light">
                <Card.Body>
                    <h3>
                        Cámara de operadores y distribuidores de gas licuado
                        (CODIGAS)
                    </h3>
                    <Card.Text>Información de contacto:</Card.Text>
                    <ul>
                        <li>
                            Dirección de email:{" "}
                            <a href="mailto:administracion@codigas.org.ar">
                                administracion@codigas.org.ar
                            </a>
                        </li>
                        <li>Telefono: 4811-4000</li>
                        <li>
                            Dirección: Cerrito 1070, Piso 2°, Oficina 52, Ciudad
                            Autónoma de Buenos Aires
                        </li>
                    </ul>
                </Card.Body>
            </Card>
        </>
    );
}
