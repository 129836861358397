import React from "react";
import { Card } from "react-bootstrap";
import Helmet from "react-helmet";

export default function QuienesSomos() {
    return (
        <>
            <Helmet>
                <title>Codigas - Quiénes Somos</title>
            </Helmet>
            <Card bg="light justificado">
                <Card.Body>
                    <Card.Title>Quiénes somos</Card.Title>
                    <Card.Text>
                        La Cámara de Operadores y Distribuidores de Gas Licuado
                        de Petróleo (CODIGAS) es una entidad sin fines de lucro,
                        que tiene como objetivo fundamental la representación de
                        las empresas y/o empresarios de la industria del GLP,
                        ante los organismos gubernamentales y no
                        gubernamentales, con el fin de:
                    </Card.Text>
                    <ul>
                        <li>Mejorar las condiciones de trabajo.</li>
                        <li>
                            Obtener y mantener una relación armónica entre los
                            asociados y de estos con las empresas productoras,
                            fraccionadoras y distribuidoras.
                        </li>
                        <li>
                            Lograr el ordenamiento legal, reglamentario y
                            normativo de todo cuanto se relaciona con la
                            distribución de gas licuado.
                        </li>
                        <li>
                            Racionalizar la actividad tendiendo al orden e
                            incremento de la eficiencia.
                        </li>
                        <li>
                            Participar en todos los temas relacionados con el
                            negocio de la distribución de gas licuado en todas
                            sus formas y modalidades.
                        </li>
                        <li>
                            Confeccionar y evaluar estadísticas sobre cuestiones
                            relacionadas con el negocio del gas licuado y su
                            distribución, producción y fraccionamiento.
                        </li>
                    </ul>
                </Card.Body>
            </Card>
        </>
    );
}
