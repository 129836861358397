import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

export default function Item({ path, text }) {
    return (
            <ListGroup.Item action target="_blank" href={path}>
                {text}
            </ListGroup.Item>
    );
}
